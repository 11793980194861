@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #d4ebfd;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
}

label {
  color: rgba(0, 0, 0, 0.653);
}

.submit-btn {
  width: 100%;
  background-color: orange;
  padding: 0.7rem;
  border-radius: 0.5rem;
  color: white;
  text-align: center;
}

.error {
  font-size: 0.8rem;
  color: red;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(193, 193, 193);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(193, 193, 193);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(193, 193, 193);
}
