.card {
  background-color: white;
  width: 33.5rem;
  height: 31.125rem;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  padding-top: 9.5rem;
}

@media only screen and (max-width: 768px) {
  .card {
    width: 90%;
  }
}
