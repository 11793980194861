.custominput {
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: 1px solid rgb(193, 193, 193);
  outline: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  color: black;
}

.customInput-flex {
  display: flex;
  justify-content: space-between;
}

.customButton {
  margin-right: 1rem;
  padding: 0.5rem;
  width: 10rem !important;
}

.gray {
  color: rgb(193, 193, 193) !important;
}

@media only screen and(max-width:764px) {
  .customButton {
    width: 6rem !important;
  }
}
