.debitcard {
  width: 400px;
  height: 250px;
  background: url('./../../assets/card-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%, -10%);
  z-index: 1;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.2);

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    img {
      width: 6rem;
      &:first-child {
        margin-top: 0.5rem;
      }
      &:last-child {
        margin-right: 1rem;
      }
    }
  }

  &__bottom {
    color: white;
    &-cardnumber {
      margin-bottom: 2rem;
      font-size: 1.5rem;
      text-align: center;
    }
    &-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__cvvWrapper {
    width: 100%;
    background-color: black;
    height: 3rem;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    &-cvv {
      text-align: end;
      width: 3rem;
      height: 1.5rem;
      background-color: white;
    }
  }
}

@media only screen and(max-width:764px) {
  .debitcard {
    width: 300px;
    height: 200px;
    &__top {
      margin-bottom: 1rem;
      img {
        width: 3rem;
        &:first-child {
          margin-top: 0.5rem;
        }
        &:last-child {
          margin-right: 1rem;
        }
      }
    }
  }
}
